<template>
    <div class="login-wrapper custom_footer signup-card-outer-wrapper">
        <the-header/>
        <div class="signup-card-wrapper">
            <div class="signup-card bg-blur">
                <div class="login-title">Sign Up</div>
                <div class="signup-inputs">
                    <div>
                        <img src="/orla/individual.png" alt="Individual Sign Up"/>
                        <button class="signup-button" type="button" @click="individualSinup">
                            Individual Sign Up
                        </button>
                    </div>
                    <div>
                        <img src="/orla/company.png" alt="Company Sign Up"/>
                        <button class="signup-button" type="button" @click="companySignup">
                            Company Sign Up
                        </button>
                    </div>
                </div>
                <div class="signup-subdiv">
                    <div>Already have an account?
                        <router-link to="/orla-login">Login</router-link>
                    </div>
                </div>
            </div>
        </div>
        <the-footer/>
    </div>
</template>
<script>
import "sweetalert2/src/sweetalert2.scss";
import '@/orla/partials/style.css';
import '@/orla/partials/responsive.css';

import TheHeader from '@/orla/partials/Header.vue';
import TheFooter from '@/orla/partials/Footer.vue';

export default {
    components: {
        TheHeader,
        TheFooter
    },
    data() {
        return {};
    },
    created: function () {
    },
    methods: {
        individualSinup: function () {
            this.$router.push("/orla-register");
        },
        companySignup: function () {
            this.$router.push("/orla-corporate-register");
        }
    }
};
</script>
<style scoped>
body a:hover {
    color: #ffffff !important;
}

@media (min-width: 768px) and (max-width: 912px){
    footer.footer.custom-footer {
        position: relative !important;
    }
}
</style>